@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@700&display=swap");

html,
body {
  margin: 0;
  background-color: #f2f2f2;
}

* {
  font-family: "Oxygen", sans-serif;
  font-weight: bold;
}

.App {
  padding-top: 5rem;
}
.board {
  display: flex;

  justify-content: center;
  flex-wrap: wrap;
}
.card {
  padding: 20px 0;
  text-align: center;
  border-radius: 6px;
  border: solid 1px #141c3a;
  background-color: #141c3a;
  color: white;
  cursor: pointer;
  height: 75px;
  font-size: 16px;
}

.card:hover,
.card:focus {
  background-color: #2e4085;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(3, 138px);
  grid-auto-rows: 1fr;
  gap: 16px 16px;
}

.card.selected {
  background-color: initial;
  color: #141c3a;
  border: solid 1px #141c3a;
}

.card.matched {
  color: #4fe0b0;
}

.card.no-match {
  color: #fd4d3f;
}

.logo-placeholder {
  width: 120px;
}

.score-container {
  background-color: #f2f2f2;
  /* padding: 18px 158px 23px 159px; */
  text-align: center;
  font-size: 32px;
}

.footer > button {
  width: 20%;
  height: 60px;

  /* padding: 19px 40px 19px 40px; */
  font-size: 20px;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.start-button {
  background-color: #017193;
  border-radius: 6px;
}

.start-button:hover,
.start-button:focus {
  background-color: #2c3f81;
}

.try-new-pair-button {
  background-color: #6400e4;
}

.try-new-pair-button:hover,
.try-new-pair-button:focus {
  background-color: #7d1aff;
}

/* Mobile */
@media screen and (max-width: 600px) {
  .App {
    padding-top: 5rem;
  }
  .board {
    display: flex;
    /* width:50%; */

    /* margin: 30px; */
    justify-content: center;
    flex-wrap: wrap;
    /* position: inherit; */
  }

  .cards-container {
    grid-template-columns: repeat(3, 100px);
    grid-auto-rows: 1fr;
    gap: 10px 10px;
  }
  .card {
    height: 60px;
    font-size: 13px;
  }
  .footer > button {
    width: 30%;
    height: 40px;

    font-size: 13px;
  }
}
